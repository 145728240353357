import React, { FC, useEffect } from 'react';
import classNames from 'classnames';

import 'focus-visible/dist/focus-visible.min.js';

import '@fontsource/montserrat/latin-400.css';
import '@fontsource/montserrat/latin-600.css';
import '@fontsource/montserrat/latin-800.css';

import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import CartModalWrapper from 'common/CartModalWrapper';
import SupportShopifyWrapper from 'common/SupportShopifyWrapper';
import PriceSpider from 'common/PriceSpider';
import BackgroundImage from 'common/BackgroundImage';
import Footer from 'components/Footer';
import Header from 'components/Header';
import SignUpPopup from 'components/SignUpPopup';
import SignUpFormPopup from 'components/SignUpFormPopup';

import { LayoutProps } from './models';

import 'styles/main.scss';
import './Layout.scss';

const Layout: FC<LayoutProps> = ({
  children,
  header,
  footer,
  seoMetaKeywords,
  seoMetaTitle,
  seoMetaTitleTemplate,
  seoMetaDescription,
  seoExternalHreflangs,
  seoNoIndex,
  siteSettings,
  brandSettings,
  imageUrl,
  imageAlt,
  purchaseSettings,
  skipText,
  warning,
  link,
  searchUrl,
  alternateUrls,
  isHideHeader,
  isHideFooter,
  pageClassName,
  pageBackgroundImage,
  signUpPopup,
  signUpFormPopup,
  singupFormBaner,
}) => {
  const isVisiblePopup = signUpPopup?.isHomePageOnly ? link === '/' : true;
  const socialLinks = footer?.socialNetworks.map(({ properties }) => properties?.link?.[0]?.url);
  useEffect(() => {
    if (siteSettings?.usePriceSpider) {
      window?.PriceSpider?.rebind();
    }
  }, [siteSettings?.usePriceSpider]);

  return (
    <div
      data-testid="layout"
      className={classNames('layout', { [`${pageClassName}`]: pageClassName })}
    >
      <Seo
        seoMetaKeywords={seoMetaKeywords}
        seoMetaTitle={seoMetaTitle}
        seoMetaTitleTemplate={seoMetaTitleTemplate}
        seoMetaDescription={seoMetaDescription}
        seoNoIndex={seoNoIndex}
        siteSettings={siteSettings}
        brandSettings={brandSettings}
        seoExternalHreflangs={seoExternalHreflangs}
        imageUrl={imageUrl}
        imageAlt={imageAlt}
        alternateUrls={alternateUrls}
        pageUrl={link}
      />
      <PageSchema
        siteSettings={siteSettings}
        brandSettings={brandSettings}
        seoData={{
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaDescription,
        }}
        additionalData={{
          socialLinks,
        }}
        type="WebSite"
        path={link}
      />
      {siteSettings?.usePriceSpider && (
        <PriceSpider
          psKey={siteSettings.psKey}
          psCountry={siteSettings.psCountry}
          psLanguage={siteSettings.psLanguage}
          psScript={siteSettings.psScript}
        />
      )}

      <a className="sr-only-skip" href="#main">
        {skipText}
      </a>
      {isHideHeader ? null : (
        <Header
          {...header}
          currentLang={siteSettings?.lang}
          warning={warning}
          searchUrl={searchUrl}
          link={link}
          alternateUrls={alternateUrls}
        />
      )}

      <CartModalWrapper cartPath={header?.cartPath} />
      <SupportShopifyWrapper purchaseSettings={purchaseSettings} />
      {signUpPopup && isVisiblePopup ? (
        <SignUpPopup
          textBeforeLogo={signUpPopup?.textBeforeLogo}
          textAfterLogo={signUpPopup?.textAfterLogo}
          link={signUpPopup?.link}
          logo={signUpPopup?.logo}
          backgroundImage={signUpPopup?.backgroundImage}
          backgroundImageMobile={signUpPopup?.backgroundImageMobile}
          closeButton={signUpPopup?.closeButton}
          productsDescription={signUpPopup?.productsDescription}
          productsImage={signUpPopup?.productsImage}
          isBigImageStyle={signUpPopup?.isBigImageStyle}
          isWithoutSessionMode={signUpPopup?.isWithoutSessionMode}
        />
      ) : null}
      {signUpFormPopup ? <SignUpFormPopup {...signUpFormPopup} /> : null}
      <main id="main">
        {children}
        {pageBackgroundImage?.length ? (
          <BackgroundImage backgroundProperties={pageBackgroundImage[0]} />
        ) : null}
      </main>

      {isHideFooter ? null : <Footer {...footer} signUp={singupFormBaner} />}
    </div>
  );
};
export default Layout;
