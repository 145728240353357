/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cloud_logic_custom: [
    {
      name: 'subscribeApi',
      endpoint: 'https://1mo1olcmsj.execute-api.eu-central-1.amazonaws.com/dev',
      region: 'eu-central-1',
    },
    {
      name: 'signUpApi',
      endpoint: 'https://pweeturgw1.execute-api.eu-central-1.amazonaws.com/dev',
      region: 'eu-central-1',
    },
  ],
};

export default awsmobile;
