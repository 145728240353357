import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import { getCookies, setCookies } from 'utils/cookies';
import useClickOutside from 'hooks/useClickOutside';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { SignUpPopup as SignUpPopupProps } from '@shared/types';

import './SignUpPopup.scss';

const SIGNUP_POPUP_COOKIE_NAME = 'signupPopupCookie';
const SIGNUP_POPUP_TIMEOUT = 10000;

const SignUpPopup: FC<SignUpPopupProps.ISignUpPopup> = ({
  textBeforeLogo,
  textAfterLogo,
  link,
  logo,
  backgroundImage,
  backgroundImageMobile,
  closeButton,
  productsDescription,
  productsImage,
  isBigImageStyle,
  isWithoutSessionMode,
}) => {
  const { isDesktop } = useScreenRecognition();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoaded, setisLoaded] = useState<boolean>(false);
  const [cookiePopupValue, setCookiePopupValue] = useState<string>('');
  const containerRef = useRef();

  useEffect(() => {
    setCookiePopupValue(getCookies(SIGNUP_POPUP_COOKIE_NAME));
    setCookies(SIGNUP_POPUP_COOKIE_NAME, 'false', '/', 30);
    setisLoaded(true);
  }, []);

  useEffect(() => {
    if (cookiePopupValue !== 'true' && isLoaded) {
      const timeout = setTimeout(() => {
        setIsVisible(true);
      }, SIGNUP_POPUP_TIMEOUT);

      return () => clearTimeout(timeout);
    }
  }, [isLoaded]);

  const onCloseModal = useCallback(() => {
    setIsVisible(false);
    if (!isWithoutSessionMode) {
      setCookies(SIGNUP_POPUP_COOKIE_NAME, 'true', '/', 30);
    }
  }, []);

  useClickOutside(containerRef, onCloseModal);

  return isVisible ? (
    <section className={classNames('signup-popup', { 'signup-popup--big-image': isBigImageStyle })}>
      <div className="signup-popup__container" ref={containerRef}>
        <div className="signup-popup__scroll-wrapper">
          {isDesktop || isBigImageStyle ? (
            <div className="signup-popup__products">
              {logo?.[0]?.properties.image ? (
                <GatsbyImage
                  image={logo[0].properties.image}
                  alt={logo[0].properties?.imageAlt}
                  style={{ position: 'absolute' }}
                  className="signup-popup__products-logo"
                />
              ) : null}
              {productsDescription ? (
                <DangerouslySetInnerHtml
                  html={productsDescription}
                  className="signup-popup__products-description"
                />
              ) : null}

              {productsImage?.[0]?.properties.image ? (
                <GatsbyImage
                  image={productsImage[0].properties.image}
                  alt={productsImage[0].properties?.imageAlt}
                  style={{ position: 'absolute' }}
                  objectFit={isBigImageStyle ? 'contain' : 'cover'}
                  className="signup-popup__products-image"
                />
              ) : null}
            </div>
          ) : null}

          <div className="signup-popup__content">
            <p className="signup-popup__text">
              {textBeforeLogo}
              {logo?.[0]?.properties.image ? (
                <GatsbyImage
                  image={logo[0].properties.image}
                  objectFit="fill"
                  alt={logo[0].properties?.imageAlt}
                  style={{ position: 'absolute' }}
                  className="signup-popup__logo"
                />
              ) : null}
              {textAfterLogo}
            </p>

            <Button
              variant="primary"
              classes="signup-popup__link"
              ariaLabel={link?.[0]?.properties?.ariaLabel}
              target={link?.[0]?.properties?.link[0].target}
              to={link?.[0]?.properties?.link[0].url}
            >
              {link?.[0]?.properties?.link[0].name}
            </Button>

            {backgroundImageMobile?.[0]?.properties.image && !isDesktop ? (
              <GatsbyImage
                image={backgroundImageMobile[0].properties.image}
                alt={backgroundImageMobile[0].properties?.imageAlt}
                style={{ position: 'absolute' }}
                className="signup-popup__background"
              />
            ) : backgroundImage?.[0]?.properties.image ? (
              <GatsbyImage
                image={backgroundImage[0].properties.image}
                alt={backgroundImage[0].properties?.imageAlt}
                style={{ position: 'absolute' }}
                className="signup-popup__background"
              />
            ) : null}
          </div>
        </div>

        <Button
          variant="primary"
          classes="signup-popup__close"
          ariaLabel={closeButton}
          clickHandler={onCloseModal}
        >
          {closeButton}
        </Button>
      </div>
    </section>
  ) : null;
};

export const query = graphql`
  fragment FragmentSignUpPopup on TSignUpPopup {
    textBeforeLogo
    textAfterLogo
    closeButton
    link {
      properties {
        ariaLabel
        link {
          name
          target
          url
        }
      }
    }
    logo {
      properties {
        imageAlt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    backgroundImage {
      properties {
        imageAlt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 736) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    backgroundImageMobile {
      properties {
        imageAlt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 736) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    productsImage {
      properties {
        imageAlt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 302) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    productsDescription
    isBigImageStyle
    isHomePageOnly
    isWithoutSessionMode
  }
`;

export default SignUpPopup;
