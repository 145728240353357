import React, { FC, useState } from 'react';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import classNames from 'classnames';
import { ISingupFormPlaceholderFront } from '@shared/types/frontend/compositions';

import { Container } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import { gtmService } from 'utils/gtmService';

import Form from './Form';
import awsconfig from '../../../aws-exports';

import '../SignUp.scss';

const SignUpComponent: FC<ISingupFormPlaceholderFront> = ({
  singupFormBaner = {},
  renderForm,
  wideMode,
  alignImage,
  image,
  thankYouComponent,
  callbackFunction,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { errorMessage, formConfig } = singupFormBaner || {};
  const { isCDSMode, isCheckboxRequired } = formConfig?.[0]?.properties || {};

  const sectionCSSClasses = classNames(
    'signup',
    {
      [`signup--image-${alignImage?.[0]}`]: alignImage,
      'signup--wide-mode': wideMode,
    },
    'signup--dark-theme'
  );

  const [formState, setFormState] = useState({
    isSent: false,
    isSending: false,
    isError: false,
  });

  const [formData, setFormData] = useState({
    first_name: '',
    lastName: '',
    email: '',
    phone: '',
    accepts_marketing: true,
    marketing_opt_in_level: 'confirmed_opt_in',
    requiredCheckbox: Boolean(isCheckboxRequired),
    policy: false,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const googleCaptcha = await executeRecaptcha('newsletterSignUp');
    const configName = isCDSMode ? 'signUpApi' : 'subscribeApi';
    const routeConfig = awsconfig?.aws_cloud_logic_custom?.find((el) => el.name === configName);

    if (routeConfig) {
      setFormState((oldState) => ({
        ...oldState,
        isSending: true,
      }));

      const endPoint = isCDSMode ? 'sign-up' : 'subscribe';
      const url = `${routeConfig.endpoint}/${endPoint}`;

      const { first_name: firstName, email, phone, lastName = 'not provided', policy } = formData;

      let body = {};

      const {
        body: configBody = '',
        headers = '',
        revisionIds = '',
      } = formConfig?.[0]?.properties || {};

      if (isCDSMode) {
        const today = new Date();

        const bodyToCreate = configBody
          .replace(/\$EmailAddress\$/gi, email)
          .replace(/\$name\$/gi, firstName)
          .replace(/\$last_name\$/gi, lastName || 'not provided')
          .replace(/\$phone\$/gi, phone)
          .replace(/\$formTime\$/gi, `${today}`)
          .replace(/\$regTime\$/gi, `${today.toISOString()}`);
        // .replace(/\$Agreements\$/gi, JSON.stringify(revisionIds));

        const parsedBodyToCreate = JSON.parse(bodyToCreate);

        parsedBodyToCreate.JsonExternalData.Agreements = revisionIds;

        if (window?.location?.search) {
          const params = new URLSearchParams(window.location.search);

          const sourceChannel = params.get('utm_source');

          if (sourceChannel && parsedBodyToCreate?.JsonExternalData?.UnmappedAttributes) {
            parsedBodyToCreate.JsonExternalData.UnmappedAttributes.referralSource = sourceChannel;
          }
        }

        body = {
          headers: JSON.parse(headers),
          body: {
            ...parsedBodyToCreate,
            accepts_marketing: true,
            Accept_targeting: policy,
            Accept_profiling: policy,
          },
          googleCaptcha,
        };
      } else {
        body = {
          customer: {
            first_name: firstName,
            email,
            accepts_marketing: true,
            Accept_targeting: policy,
            Accept_profiling: policy,
            marketing_opt_in_level: 'confirmed_opt_in',
            last_name: lastName || 'not provided',
            tags: headers,
          },
          first_name: firstName,
          email,
          googleCaptcha,
        };
      }

      axios
        .post(url, body)
        .then(() => {
          setFormState((oldState) => ({
            ...oldState,
            isSent: true,
          }));
          gtmService.emitGenerateLead(gtmService.formNames.newsletter);
          if (callbackFunction) {
            callbackFunction();
          }
        })
        .catch((error) => {
          console.error(error);
          setFormState({
            isSent: false,
            isSending: false,
            isError: true,
          });
        });
    }
  };

  const ThankYou = thankYouComponent;

  return renderForm ? (
    <div className={sectionCSSClasses} data-testid="signup">
      <Container fluid>
        <div className="signup__grid">
          {image?.length ? (
            <div className="signup__image">
              <GatsbyImage
                image={image[0].properties.image}
                objectFit={wideMode ? 'cover' : 'contain'}
                className="signup__image-img"
                alt={image[0].properties.imageAlt}
              />
            </div>
          ) : null}
          {!formState.isSent ? (
            <Form
              {...{
                ...singupFormBaner,
                formData,
                setFormData,
                handleSubmit,
                formState,
                errorMessage,
                setFormState,
                isCheckboxRequired,
              }}
            />
          ) : null}
          {formState.isSent && !!ThankYou ? ThankYou : null}
        </div>
      </Container>
    </div>
  ) : null;
};

export default SignUpComponent;
