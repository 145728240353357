import React, { useEffect, useState, useRef } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { isBrowser } from 'utils/browser';
import { graphql } from 'gatsby';

import SignUpComponent from './components/SignUpComponent';
import SignUpComponentWithoutImage from './components/SignUpComponentWithoutImage';
import ThankYou from './components/ThankYou';

const SignUp = (props) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const observer =
    isBrowser() &&
    new IntersectionObserver(([entry]) => {
      if (!show && entry.isIntersecting) {
        setShow(true);
      }
    });

  useEffect(() => {
    observer?.observe(ref.current);

    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <div ref={ref} id="sign-up">
      {show ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
          scriptProps={{ async: true, defer: false, appendTo: 'body' }}
        >
          {props.signupType?.[0] ? (
            <SignUpComponentWithoutImage
              {...props}
              thankYouComponent={
                <ThankYou
                  message={props?.singupFormBaner?.message}
                  cta={props?.singupFormBaner?.cta}
                />
              }
            />
          ) : (
            <SignUpComponent
              {...props}
              thankYouComponent={
                <ThankYou
                  message={props?.singupFormBaner?.message}
                  cta={props?.singupFormBaner?.cta}
                />
              }
            />
          )}
        </GoogleReCaptchaProvider>
      ) : (
        <SignUpComponent {...props} />
      )}
    </div>
  );
};

export const query = graphql`
  fragment FragmentSignUpBanner on TSignupFormBanner {
    message
    title
    description
    labelEmail
    labelFirstName
    labelLastName
    labelCheckbox
    labelPhone
    phonePlaceholder
    phonePattern
    button
    ariaButton
    disclaimer
    cta {
      properties {
        link {
          url
          target
          name
        }
        ariaLabel
      }
    }
    errorMessage
    formConfig {
      properties {
        headers
        body
        cdp
        revisionIds {
          BusinessId
          RevisionId
          ConsentDesc
          MandatoryInd
          ConsentAcceptedInd
          AgreementDate
          ActivityDate
        }
        isCDSMode
        isCheckboxRequired
      }
    }
    collapsibleCheckboxText
    additionalLabelCheckbox
    additionalCollapsibleCheckboxText
    personalizeAdvertising
    extraLabelCheckbox
  }
`;

export const query2 = graphql`
  fragment FragmentSignUp on TSignupPlaceholder {
    properties {
      renderForm
      alignImage
      signupType
      wideMode
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      isCDSMode
    }
    structure
  }
`;

export default SignUp;
