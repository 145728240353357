import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Header as HeaderProps } from '@shared/types/umbraco';
import Warning from 'components/Warning';
import MainHeader from './components/MainHeader';
import TopHeader from './components/TopHeader';
import Dropdown from './components/Navigation/components/Dropdown';
import './Header.scss';

const BodyHeaderMenuClassName = 'header-modal-open';

const Header: FC<HeaderProps.IHeader> = ({
  logo,
  navigation,
  ariaBurger,
  topHeaderNavigation,
  ariaSearch,
  ariaSearchSubmit,
  ariaSearchInput,
  ariaClear,
  searchPlaceholder,
  ariaSearchForm,
  currentLang,
  warning,
  searchUrl,
  link,
  alternateUrls,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const langs = topHeaderNavigation?.[0]?.properties?.languageSelector?.[0]?.properties?.langs;

  useEffect(() => {
    isMenuOpen
      ? document.body.classList.add(BodyHeaderMenuClassName)
      : document.body.classList.remove(BodyHeaderMenuClassName);
  }, [isMenuOpen]);

  return (
    <header data-testid="header" className="header">
      {topHeaderNavigation?.[0]?.properties ? (
        <TopHeader
          topHeaderLeftBlock={topHeaderNavigation[0].properties.topHeaderLeftBlock}
          languageSelector={topHeaderNavigation[0].properties.languageSelector}
          topHeaderRightBlock={topHeaderNavigation[0].properties.topHeaderRightBlock}
          currentLang={currentLang}
          isMenuOpen={isMenuOpen}
          alternateUrls={alternateUrls}
          link={link}
        />
      ) : null}
      <MainHeader
        handleCloseMenu={() => setIsMenuOpen(false)}
        handleOpenMenu={() => setIsMenuOpen(true)}
        isMenuOpen={isMenuOpen}
        ariaBurger={ariaBurger}
        logo={logo}
        navigation={navigation}
        ariaSearch={ariaSearch}
        ariaSearchSubmit={ariaSearchSubmit}
        ariaSearchInput={ariaSearchInput}
        ariaSearchForm={ariaSearchForm}
        ariaClear={ariaClear}
        searchPlaceholder={searchPlaceholder}
        searchUrl={searchUrl}
        currentLink={link}
        topHeaderRightBlock={topHeaderNavigation?.[0]?.properties?.topHeaderRightBlock}
        topHeaderLeftBlock={topHeaderNavigation?.[0]?.properties?.topHeaderLeftBlock}
      />
      {!isMenuOpen && (
        <Dropdown
          navigation={navigation}
          currentLang={currentLang}
          langs={langs}
          currentLink={link}
        />
      )}
      {warning && <Warning warning={warning} navigation={navigation} />}
    </header>
  );
};

export const query = graphql`
  fragment FragmentHeader on THeader {
    cartPath {
      properties {
        link {
          url
        }
        ariaLabel
      }
    }
    logo {
      properties {
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 115) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        link {
          name
          url
        }
        ariaLabel
        imageAlt
      }
    }
    topHeaderNavigation {
      properties {
        topHeaderLeftBlock {
          properties {
            link {
              target
              name
              url
              icon
            }
            ariaLabel
            icon
          }
        }
        languageSelector {
          properties {
            langs {
              properties {
                title
                code
                prefix
                ariaLabel
              }
            }
            inHeader
            redirectToHome
          }
        }
        topHeaderRightBlock {
          properties {
            link {
              target
              name
              url
            }
            ariaLabel
            icon
          }
        }
      }
    }
    navigation {
      properties {
        label
        link {
          properties {
            link {
              name
              url
            }
            ariaLabel
          }
        }
        withDropdown
        subNavigation {
          ... on TImageCard {
            properties {
              image {
                properties {
                  image {
                    ...FragmentGatsbyProps
                    gatsbyImage {
                      childImageSharp {
                        fluid(maxWidth: 1200) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  imageAlt
                }
              }
              description
              link {
                name
                url
                target
              }
              isImageLink
            }
          }
          ... on THeaderNavigation {
            properties {
              label
              link {
                properties {
                  ariaLabel
                  link {
                    name
                    url
                  }
                  ariaLabel
                }
              }
            }
          }
        }
      }
    }
    searchPlaceholder
    ariaBurger
    ariaClear
    ariaSearch
    ariaSearchSubmit
    ariaSearchInput
    ariaSearchForm
  }
`;

export default Header;
